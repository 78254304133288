import PageSections from "@/components/PageSections";
import craft from "@/lib/craft";
import Query404 from "@/queries/Query404";
import QueryNavigation from "@/queries/QueryNavigation";
import type { GetStaticProps, NextPage } from "next";
import Head from "next/head";
import { Seomatic } from "react-seomatic";

export const getStaticProps: GetStaticProps = async () => {
  const response = await craft([
    { document: QueryNavigation },
    { document: Query404 },
  ]);

  const { primaryNavigation, footerNavigation, copyrightNavigation } =
    response[0];
  const { pageEntry } = response[1];

  return {
    props: {
      primaryNavigation,
      footerNavigation,
      copyrightNavigation,
      pageEntry,
    },
    revalidate: process.env.VERCEL_ENV !== "production" ? 1 : 3600,
  };
};

const Page: NextPage = ({ pageEntry }) => {
  return (
    <>
      <Seomatic Head={Head} {...pageEntry.seomatic} />
      <PageSections pageEntry={pageEntry} sections={pageEntry.pageSections} />
    </>
  );
};

export default Page;
